import React from "react"

class Terms extends React.Component {
render() {
  return (
    <div style = {{maxWidth:`500px`, margin:`auto`}}>
    <h1>Terms of Service</h1>

    <p>PLEASE READ THE FOLLOWING TERMS OF SERVICE BEFORE USING THE SITE OR SUBMITTING AN EMAIL. BY PROVIDING YOUR EMAIL, OR ACCESSING ANY PAGE ON OUR SITE, YOU AGREE TO BE BOUND BY THE CURRENT VERSION OF
      OUR TERMS OF SERVICE AND PRIVACY POLICY.</p>

    <h3>1. General.</h3>
    <p>Welcome to the website The Service requires that Users have a valid email for as long
      as Users choose to subscribe to and use the Service.</p>

    <h3>2. Acceptance of the Terms of Use.</h3>
    <p>We ask that you review and abide by these Terms and Conditions, our <a href="/privacy-policy" target="_blank">Privacy Policy</a>, and any other terms and conditions that may appear on the Site from time to time. Your use of the Service constitutes your agreement to these Terms and Conditions, and we reserve the right to revise these Terms and Conditions at any time without notice to you. When we make revisions, we will post them on the Site and they will be effective immediately upon posting. You agree to check this section periodically to be aware of any changes to the Terms and Conditions. YOUR CONTINUED USE OF THE SERVICES AFTER THE POSTING OF ANY REVISIONS SHALL BE CONSIDERED YOUR AGREEMENT TO THE MODIFIED TERMS AND CONDITIONS. If you do not agree to these Terms and Conditions, please do not use the Service.</p>
    <p>The Service is offered and available to users who are 18 years of age or older. By using the Services, you represent and warrant that you are of legal age to form a binding contract with us and meet all of the foregoing eligibility requirements. If you do not meet all of these requirements, you must not access or use the Service.</p>
    <p>The Service and its Content are intended solely for personal and non-commercial use by you. Any use of the Service or its Content other than for personal and non-commercial purposes is strictly prohibited.</p>

    <h3>3. What We Own.</h3>
    <p>Unless otherwise noted, all material and services available on the Site or through the App, and all material and services provided by or through the Service, its affiliates, subsidiaries, employees, agents, licensors or other commercial partners including, but not limited to, software, all informational text, software documentation, design of and "look and feel," layout, photographs, graphics, audio, video, messages, interactive and instant messaging, design and functions, files, documents, images, or other materials, whether publicly posted or privately transmitted as well as all derivative works thereof (collectively, the "Content") are the intellectual property of us, our licensors, and our contributors. The Content is protected by U.S. copyright, trademark, trade-dress, and any other applicable national or international intellectual property laws. All trademarks and service marks, logos, slogans and taglines are the property of us. All other trademarks, service marks, logos, slogans and taglines are the property of their respective owners. Except as otherwise specifically provided herein, nothing should be construed as granting any license or right to use any trademarks, service marks, logos, slogans or taglines displayed without our express written permission, or the express written permission of such third-party that may own the trademark, service mark, logo, slogan or tagline.</p>

    <h3>4. Our License to You.</h3>
    <p>Subject to this Agreement, we hereby grant you a limited, revocable, non-transferable and non-exclusive license to use the Service through a user identification reference provided by us ("User ID") to the extent, and only to the extent, necessary to access and use our Service in accordance with the terms of this Agreement. This license does not permit you, and you agree not to: store, copy, reproduce, republish, modify, upload, post, translate, scrape, rent, lease, loan, sell, distribute, transfer, transmit, display, decompile, reverse engineer, reverse assemble, decipher or otherwise attempt to discover any programming code or any source code used in or with the Service or otherwise distribute in any way the components of the Service other than as specifically permitted in this Agreement. You may not sell, assign, sublicense, grant a security interest in or otherwise attempt to transfer any right in the Service, create derivative works based on or in any manner commercially exploit the Service, in whole or in part, other than as expressly permitted in this Agreement. Any use of the Service for any purpose other than as specifically permitted herein or without our prior consent or the prior written consent of our licensors, as applicable, is expressly prohibited. We reserve all rights not expressly granted in this Agreement.</p>

    <h3>5. Use of the Service.</h3>
    <p>We reserve the right to withdraw or amend this Site, our App, and any Service or material we provide, including the text messaging and wellness audio content services, in our sole discretion without notice. We will not be liable if, for any reason, all or any part of the Site or Service is unavailable at any time or for any period. From time to time, we may restrict access to some parts of the Site, or our App, to users, including registered users.</p>
    <p>To access the Service, you may be asked to provide certain registration details or other information, including a valid cellphone number, and to link an ApplePay account. It is a condition of your use of the Service that all the information you provide on the Site to use the Service is correct, current and complete. You agree that all information you provide to register with this Site or App, including but not limited to through the use of any interactive features, is governed by our <a href="/privacy-policy" target="_blank">Privacy Policy</a>, and you consent to all actions we take with respect to your information consistent with our Privacy Policy.</p>
    <p>Purchasers of our wellness audio content will need to pay for a monthly or annual subscription prior to unlocking the content. All sales are final, and we do not offer refunds for our audio content subscription.</p>
    <p>If you choose, or are provided with, a user name, password or any other piece of information as part of our security procedures, you must treat such information as confidential, and you must not disclose it to any other person or entity. You also acknowledge that your account is personal to you and agree not to provide any other person with access to the Service using your user name, password, cell phone number, or other security information. You agree to notify us immediately of any unauthorized access to or use of your username or password or any other breach of security. You also agree to ensure that you exit from your account at the end of each session. You should use particular caution when accessing your account from a public or shared computer so that others are not able to view or record your password or other personal information.</p>
    <p>We have the right to disable any user name, cellphone number associated with an account, password, or other identifier, whether chosen by you or provided by us, at any time in our sole discretion for any or no reason, including if, in our opinion, you have violated any provision of these Terms of Service or our <a href="/privacy-policy" target="_blank">Privacy Policy</a>.</p>

    <h3>6. Reliance on the Information Sent.</h3>
    <p>The information sent via our Service, or presented on or through the Site or our App, is made available solely for general information purposes. We do not warrant the accuracy, completeness, usefulness, or safety of this information. Any reliance you place on such information is strictly at your own risk. WE DISCLAIM ALL LIABILITY AND RESPONSIBILITY ARISING FROM ANY RELIANCE PLACED ON SUCH INFORMATION BY YOU OR ANY OTHER VISITOR TO THE SITE, OR BY ANYONE WHO MAY BE INFORMED OF ANY OF ITS CONTENTS OR THE CONTENTS OF THE SERVICE.</p>

    <h3>7. Content You Provide to Us.</h3>
    <p>You are legally responsible for all information, data, text, software, music, sound, photographs, graphics, video, messages or other Content uploaded, posted or stored in connection with your use of the our Service. We are not responsible for your Content. You hereby grant us a worldwide, royalty-free, non-exclusive license to host and use the Content in order to provide you with the Service, and hereby represent and warrant that you have all the rights necessary to grant us such license. You are responsible for any Content that may be lost or unrecoverable through your use of the Service. You are encouraged to archive your Content regularly and frequently.</p>
    <p>All information we collect on this Site, through our App, or through your use of the Service is subject to our <a href="/privacy-policy" target="_blank">Privacy Policy</a>. By using the Site, App, and Service, you consent to all actions taken by us with respect to your information in compliance with the <a href="/privacy-policy" target="_blank">Privacy Policy</a>.</p>

    <h3>8. Geographic Restrictions</h3>
    <p>The owner of the Service is based in the state of New York in the United States. We make no claims that the Site, App, Service, or any of its content is accessible or appropriate outside of the United States. Access to the Service may not be legal by certain persons or in certain countries. If you access the Service from outside the United States, you do so on your own initiative and are responsible for compliance with local laws and cell phone service provider rates and terms of service.</p>

    <h3>9. User Comments.</h3>
    <p>We currently do not offer public comment functionality. However, when we implement this functionality Users must abide by the following rules and requirements:</p>
    <ul>
      <li>Users must either register an account with us or use a social media integrated login in order to comment publicly on the Site;</li>
      <li>Our community rules (below) apply to all comments. We periodically moderate comments. We reserve the right to block commenters, remove or amend comments, and to remove user accounts entirely at our discretion, and/or for reasons including, but not limited to, violation of our Terms of Service;</li>
      <li>By posting comments to the Site, you hereby grant to us a non-exclusive, royalty-free, perpetual, transferable, irrevocable, fully paid-up, worldwide, and sub-licensable right to use, copy, reproduce, modify, adapt, translate, distribute, publish, create derivative works of, display, perform, and otherwise disclose or incorporate into other works, the comments for any purpose to the extent permissible by applicable law;</li>
      <li>Additionally, you grant us and our subsidiaries the right to use your username in connection with said comments, subject to our Privacy Policy;</li>
    </ul>
    <p>Notwithstanding the related provisions in our Privacy Policy, comments submitted by you are deemed non-confidential and we are under no obligation to treat such comments as proprietary information. Without limiting the foregoing, we reserve the right to display the comments as it deems appropriate, including, without limitation, deleting, rejecting, or refusing to post them.</p>

    <h3>10. Community Rules.</h3>
    <ol type="a">
      <li>
        <em>No nudity, pornography, or depictions of sexual activity</em>
      </li>
      <ol type="i">
        <li>This includes images containing the explicit display of sexual organs especially intended to stimulate erotic feelings,
          full or partial nudity, any depiction of sexual activity,
          <a href="https://en.wikipedia.org/wiki/Softcore_pornography">softcore</a> or
          <a href="https://en.wikipedia.org/wiki/Hardcore_pornography">hardcore</a> pornography.</li>
        <li>This includes links posted in comments.</li>
        <li>If you aren't sure, please don't post it.</li>
      </ol>
      <li>
        <em>No hate speech or abusive content.</em>
      </li>
      <ol type="i">
        <li>Racism, sexism, slurs, personal attacks, death threats, suicide requests or any form of hate speech is not tolerated.</li>
        <li>This includes hate speech or abuse in usernames, as well as images and comments.</li>
      </ol>
      <li>
        <em>No Advertising, SPAM, or other links to commercial websites</em>
      </li>
      <ol type="i">
        <li>There's too much SPAM on the Internet already - don't add to it.</li>
      </ol>
      <li>
        <em>No Location Information / Doxing</em>
      </li>
      <ol type="i">
        <li>Do not post your or another individual's street address or any information that may lead another user to discover your
          real address. Users should include no information that makes them publicly identifiable.</li>
      </ol>
    </ol>

    <h3>11. Giveaways.</h3>
    <p>We may, from time to time, offer giveaways ("Promotions") to Users in order to encourage engagement with the platform.
      The following general rules ("Rules") apply to all Promotions we conduct, and Users agree to be bound by these rules:</p>
    <ol>
      <li>NO PURCHASE OR PAYMENT OF ANY KIND IS NECESSARY TO ENTER OR WIN PROMOTIONS. A PURCHASE WILL NOT IMPROVE YOUR
        CHANCES OF WINNING.</li>
      <li>
        <strong>Promotion Period:</strong> Any Promotion period we establish is subject to change.</li>
      <li>
        <strong>Sponsors:</strong> We may partner with third-party sponsors ("Sponsor") for each Promotion, or may itself be the
        sole Sponsor.</li>
      <li>
        <strong>Eligibility:</strong> Unless otherwise stated, Promotions are only open to (1) U.S., U.S. Territories and Outlying
        Areas, regardless of the residency of such individuals, or (2) persons who reside in the fifty (50) United States &
        District of Columbia, regardless of the citizenship of such individuals; provided, however, that officers, directors
        and employees of any Sponsor (if applicable), the judging organization (if applicable) or any other party associated
        with the development or administration of a Promotion, and the immediate family (i.e., parents, children, siblings,
        spouse) and persons residing in the same household as such individuals, are not eligible for any Promotions.</li>
      <li>
        <strong>Void Where Prohibited:</strong> Promotions are void where prohibited or restricted by law. Residents of any state or
        jurisdiction in which this Promotion constitutes a lottery, game of chance or otherwise unlawful game, contest or sweepstakes
        are ineligible to enter or win this Promotion and are responsible for ensuring that the Promotion is legal in their
        jurisdiction before entering any such Promotion.</li>
      <li>
        <strong>Submitted Information:</strong> All entrant information, including email addresses and mobile numbers, is subject to
        the Privacy Policy of the Promotion's Sponsor and, if applicable, our Privacy Policy. By entering a Promotion,
        all entrants fully and unconditionally agree to be bound by these Rules and the decisions of the Promotion's Sponsor,
        which will be final and binding in all matters relating to a Promotion. Sponsors reserve the right to disqualify any
        entrant for any reason, in their sole and absolute discretion.</li>
      <li>
        <strong>Winner Selection:</strong> Winners of each Promotion will be selected at random from all valid entries as of the drawing
        date, which will be set forth by us and/or the Promotion's Sponsor. The odds of winning depend on the number of
        eligible entries received for a particular Promotion.</li>
      <li>
        <strong>Winner Notification:</strong> The winner of each Promotion will be notified by email and/or telephone. If the winner
        cannot be contacted, does not respond within three (3) days from the date the Sponsor first tries to notify him/her,
        or the notification is returned as undeliverable, such winner will forfeit all rights to the Promotion prize and an
        alternate winner may be selected. If four (4) winners have so forfeited their rights to the prize, the Sponsor reserves
        the right to revoke the prize entirely. Upon contacting a winner and determining that he/she has met all eligibility
        requirements of a Promotion, including, without limitation, the execution of required waivers, publicity and liability
        releases and disclaimers, such individual will be declared the winner of said Promotion (the " Winner ").</li>
      <li>
        <strong>Taxes:</strong> All federal, state and/or local income and other taxes, if any, are the Winner's sole responsibility
        and will not be paid by us. It is the Winner's responsibility to ensure tax compliance and payment and we are
        not responsible for any failure by Winner to pay any applicable taxes. </li>
      <li>
        <strong>Ownership:</strong> All entry materials become the property of the Sponsor and will not be acknowledged or returned.</li>
      <li>
        <strong>No Transferability:</strong> No prize or any portion thereof is transferable or redeemable for cash. Any portion of
        said prize that is not used will be forfeited. Prizes cannot be substituted except by the Sponsor.</li>
      <li>
        <strong>Consent & Release:</strong> By entering a Promotion, each entrant releases and discharges the Promotion's Sponsor,
        the judging organization (if applicable), and any other party associated with the development or administration
        of said Promotion, their parent, subsidiary and affiliated entities, and each of their respective officers, directors,
        members, shareholders, partners, employees, independent contractors, agents, representatives, successors and assigns,
        from any and all liability whatsoever in connection with the Promotion.</li>
      <li>
        <strong>Disclaimers:</strong> Entries that are lost, late, misdirected, incorrect, garbled or incompletely received, for any
        reason, including by reason of hardware, software, browser or network failure, malfunction, congestion or incompatibility
        with a Sponsor's servers or elsewhere, will not be eligible. In the event of a dispute, entries will be deemed submitted
        by the authorized account holder of the email address submitted. The Promotion's Sponsor, in its sole discretion, reserves
        the right to disqualify any person tampering with the entry process or the operation of the Promotion's landing page.
        Use of bots or other automated processes to enter Promotions is prohibited and may result in disqualification at the
        sole discretion of the Promotion's Sponsor and/or us.</li>
      <li>
        <strong>Applicable Laws & Jurisdiction:</strong> This Promotion is subject to all applicable federal, state and local laws
        and regulations. Issues concerning the construction, validity, interpretation and enforceability of these Official
        Rules shall be governed by the laws of the State of New York.</li>
    </ol>

    <h3>12. Image and Video.</h3>
    <p>We may send or display images, audio, and video (the "Material") from time to time. The types of Material Users are authorized to access on the Site includes Material commissioned by us, embedded Material, Material we believe to be covered by the Fair Use Doctrine, Material from photographic archive and video vendors, and Material supplied to our staff or released into the public domain by public relations and marketing companies for press purposes.</p>

    <h3>13. Copyright Notices.</h3>
    <p>In accordance with the Digital Millennium Copyright Act ("DMCA"), we will remove any Content if properly notified that
      such Content infringes on another's intellectual property rights. We reserve the right, at our sole discretion, to remove
      any Content without prior notice.</p>
    <p>If the Material falls into one of the categories listed above under
      <strong>Image and Video</strong>, we believe that our use is legitimate and we may not remove it from the site. If you have corresponded
      with us directly, and thereafter choose to pursue a copyright notice, please note that we will respond only to notices
      of alleged infringement that comply with the DMCA. The text of the Act can be found at the
      <a href="http://www.copyright.gov/legislation/dmca.pdf">U.S. Copyright Office Web Site</a>.</p>
    <p>To file a notice of infringement with us, you must provide a written communication with
      an attached and signed PDF that sets forth the items specified below. If we do not respond in 10 business days, please
      write again – high email volume and spam means we sometimes miss emails.</p>
    <p>To enable us to address your concerns quickly and efficiently, please provide the following information in your notice
      email:</p>
    <ul>
      <li>For each alleged infringement that you wish to have removed, please provide the exact URL for the page containing the
        Material.</li>
      <li>Provide information reasonably sufficient to permit us to contact you: an email address is preferred, as well as a telephone
        number.</li>
      <li>For images, provide the following detailing your claim to ownership of the copyright in the allegedly infringing image:</li>
      <ul>
        <li>Proof of copyright in the image concerned, namely proof of copyright registration of the Image, or, absent such registration,
          a detailed description of the image – where it was taken, by whom, who or what the subject of the image is, and evidence
          to support your claim that you own the copyright. We may not comply with requests to remove an image if you cannot
          prove that you own the copyright in the image in question.</li>
        <li>Include the following statement: "I swear, under penalty of perjury, that the information in the notification is accurate
          and that I am the copyright owner or am authorized to act on behalf of the owner of an exclusive right that is allegedly
          infringed."</li>
      </ul>
      <li>Sign the document and email it.</li>
    </ul>
    <p>You acknowledge that if you fail to comply with all of the requirements of this section, your DMCA notice may not be valid
      and we will have no obligation to respond or acknowledge receipt of your notice. Please note that you will be liable
      for damages (including costs and attorneys' fees) if you materially misrepresent that any Material on our website that infringes
      your copyrights.</p>

    <h3>14. Accuracy of Information.</h3>
    <p>While we strive for accuracy, information on the site may sometimes contain errors or inaccuracies. We do not make
      any warranty as to the correctness or reliability of the site's content or any text messages we send out to Users.</p>

    <h3>15. Email Correspondence.</h3>
    <p>Emails sent to any of our email addresses are considered our property. You can read more about this in our
      <a href="/privacy-policy"
        target="_blank">Privacy Policy</a>. If you wish to remain anonymous, please specify this in the body of the email itself and we will
      do our best to respect your wishes.</p>

    <h3>16. Links.</h3>
    <p>Our Site will occasionally contain links to, and quotation of, Material from other sites. We are responsible for neither
      the content nor the privacy practices of other sites. We encourage our users to be aware when they leave the Site,
      and to read the privacy statements of any website that may collect personally identifiable information.</p>

    <h3>17. Information Security.</h3>
    <p>No data transmission over the Internet can be guaranteed to be 100% safe. Thus, we cannot warrant that your information
      will be absolutely secure. We have a variety of safeguards – technical, administrative, and physical – in place to
      help protect against unauthorized access to, use, or disclosure of user information.</p>

    <h3>18. The Legal Stuff – Disclaimer and Limitation of Liability.</h3>
    <p>EXCEPT AS EXPRESSLY SET FORTH IN THESE TERMS AND CONDITIONS, YOU EXPRESSLY UNDERSTAND AND AGREE THAT THE SITE, CONTENT,
      PRODUCTS AND/OR SERVICE ON THE SITE OR ELSEWHERE ARE PROVIDED "AS IS" AND ON AN "AS AVAILABLE" BASIS. TO THE FULLEST
      EXTENT PERMITTED BY LAW AND EXCEPT AS EXPRESSLY SET FORTH IN THESE TERMS AND CONDITIONS, WE DISCLAIM ALL WARRANTIES,
      EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE
      AND NON-INFRINGEMENT. WE DO NOT REPRESENT OR WARRANT THAT THE SITE WILL BE UNINTERRUPTED OR ERROR-FREE, THAT ANY
      DEFECTS WILL BE CORRECTED, OR THAT THE SITE OR THE SERVER THAT MAKES THE SITE AVAILABLE ARE FREE FROM VIRUSES OR ANYTHING
      ELSE HARMFUL. FURTHER, EXCEPT AS EXPRESSLY SET FORTH IN THESE TERMS AND CONDITIONS, WE MAKE NO WARRANTIES OR REPRESENTATIONS
      ABOUT THE ACCURACY, ADEQUACY, USEFULNESS, RELIABILITY, OR COMPLETENESS OF THE SITE, TEXT MESSAGES, SERVICES, CONTENT,
      THE CONTENT OF ANY THIRD-PARTY SITE LINKED TO OR FROM THIS SITE, COMMENTS, INFORMATION, INFORMATION PROVIDED BY US OR
      OUR VENDORS, OR ANY OTHER ITEMS OR MATERIALS ON THE SITE OR LINKED TO FROM THE SITE.</p>
    <p>THIS SERVICE ASSUMES NO LIABILITY OR RESPONSIBILITY FOR (A) ANY, ERRORS, MISTAKES OR INACCURACIES OF THE CONTENT, PRODUCTS, SERVICES,
      INFORMATION, SITE AND MATERIALS SET FORTH ON OR MADE AVAILABLE THROUGH THE SITE, (B) PERSONAL INJURY OR PROPERTY DAMAGE,
      OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO OR USE OF THE SITE, PRODUCTS, SERVICES OR ANY THIRD PARTY SITE(S),
      PRODUCTS OR SERVICES, (C) ANY UNAUTHORIZED ACCESS TO OR USE OF THE SERVERS THAT HOST THE SITE OR ANY THIRD PARTY SITE(S)
      AND/OR ANY AND ALL PERSONAL INFORMATION STORED THEREIN, (D) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM
      THE SITE OR THIRD PARTY SITE(S), (E) ANY BUGS, VIRUSES, TROJAN HORSES OR THE LIKE, WHICH MAY BE TRANSMITTED TO OR THROUGH
      THE SITE OR ANY THIRD PARTY SITE(S) BY US OR ANY THIRD PARTY, AND/OR (F) ANY ERRORS OR OMISSIONS IN THE NETWORK OR
      ANY CONTENT, INFORMATION AND MATERIALS (INCLUDING BUT NOT LIMITED TO THIRD PARTY SITE(S)) OR FOR ANY LOSS OR DAMAGE OF
      ANY KIND INCURRED AS A RESULT OF THE USE OF ANY OF THE FOREGOING.</p>
    <p>NO PERSON (INCLUDING ANY AGENT, DEALER OR REPRESENTATIVE OF THIS SERVICE) IS AUTHORIZED TO MAKE ANY REPRESENTATION OR WARRANTY
      CONCERNING OUR SITE AND SERVICES, AND YOU ACKNOWLEDGE AND AGREE THAT YOU HAVE NOT RELIED ON ANY OTHER WARRANTIES
      OR REPRESENTATIONS.</p>
    <p>IN NO EVENT SHALL THIS SERVICE OR ITS SUBSIDIARIES, AFFILIATES, AGENTS, SUPPLIERS, VENDORS, MANUFACTURERS OR DISTRIBUTORS BE LIABLE
      FOR ANY INDIRECT, SPECIAL, PUNITIVE, INCIDENTAL, EXEMPLARY OR CONSEQUENTIAL DAMAGES, INCLUDING, WITHOUT LIMITATION, DAMAGES
      FOR LOSS OF USE, DATA, REVENUE OR PROFITS, BUSINESS INTERRUPTION, OR LOSS OF BUSINESS OPPRTUNITY OR GOODWILL, ARISING
      FROM OR IN CONNECTION WITH (A) THE USE OF, OR INABILITY TO USE, THE SITE; (B) THE PROVISION OF OR FAILURE TO PROVIDE
      SERVICES, MATERIALS, CONTENT, OR SOFTWARE AVAILABLE FROM, ON OR THROUGH THE SITE OR ANY THIRD-PARTY WEBSITE(S); OR (C)
      THE CONDUCT OF OTHER USERS OF THE SITE, WHETHER BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY OR OTHERWISE, EVEN
      IF THIS SERVICE HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. YOU ASSUME COMPLETE RESPONSIBILITY FOR YOUR USE OF THE
      SITE. YOUR SOLE REMEDY AGAINST US FOR DISSATISFACTION WITH THE SITE OR ANY CONTENT IS TO STOP USING THE WEBSITE. THAT
      SAID, IF WE ARE FOUND TO BE LIABLE TO YOU FOR ANY DAMAGE OR LOSS ARISING OUT OF OR WHICH IS IN ANY WAY CONNECTED WITH
      YOUR USE OF THE SITE, ANY CONTENT, OR PURCHASE OF ANY PRODUCTS OR SERVICES ON OR THROUGH THE SITE, OUR LIABILITY
      SHALL NOT EXCEED $100.00 IN THE AGGREGATE.</p>

    <h3>19. Termination.</h3>
    <p>These Terms of Service are effective unless and until terminated by either you or our service. You may terminate these Terms
      of Service as they apply to you at any time by ceasing to use the Site. We may terminate these Terms of Service at
      any time immediately and without notice, and accordingly deny you access to the Site, for any reason in its sole discretion;
      however, the provisions in these Terms of Service that relate to intellectual property, indemnification, disclaimer,
      limitation of liability, and choice of law shall survive any termination of these Terms of Service.</p>

    <h3>21. Governing Law and Jurisdiction.</h3>
    <p>These Terms of Service shall be construed in accordance with the laws of the State of New York, without regard to any conflict
      of law provisions. Any dispute arising under these Conditions shall be resolved exclusively by the state and/or federal
      courts of the State of New York. Our performance of these Terms of Service is subject to existing laws and legal
      process, and nothing contained in this these Terms of Service is in derogation of our right to comply with law enforcement
      requests or requirements.</p>

    <h3>22. Entire Agreement.</h3>
    <p>These Terms of Service (together with our Privacy Policy, which is expressly incorporated herein by reference and which
      can be accessed on this Site, and any other terms that may appear on the Site from time-to-time) contain the entire understanding
      and agreement between you and us with respect to your use and access of this Site, and supersede all prior agreements,
      terms, conditions and understandings, both written and oral, with respect to such use and access of the Site. No representation,
      statement or inducement, whether oral or written, not contained in these Terms of Service (and any other terms that may
      appear on the Site from time-to-time) or the Privacy Policy shall bind any party to this agreement. No additional or
      different terms or conditions will be binding upon us unless expressly agreed to in writing by an officer of our service. No
      other representative has any authority to waive, alter, vary or add to these Terms of Service. Before using this Site
      please read through all referenced documents carefully.</p>

    <h3>23. Severability.</h3>
    <p>If any portion of these Terms of Service is held to be invalid or unenforceable, the invalid or unenforceable portion shall
      be modified in accordance with the applicable law with a provision that most closely reflects the intention of the original
      provision, and the remainder of these Terms of Service shall remain in full force and effect. The failure of us to
      insist upon or enforce strict performance by you of any provision of these Terms of Service shall not be construed as
      a waiver of any provision or right.</p>

    <h3>24. Changes to our Terms of Service.</h3>
    <p>We may revise and update these Terms of Service from time to time in our sole discretion. All changes are effective immediately
      when we post them, and apply to all access to and use of the Site thereafter. However, any changes to the dispute resolution
      provisions set forth in Governing Law and Jurisdiction and Arbitration above will not apply to any disputes for which
      the parties have actual notice on or prior to the date the change is posted on the Site.</p>

    <p>Your continued use of the Website following the posting of revised Terms of Service means that you accept and agree to
      the changes. You are expected to check this page from time to time so you are aware of any changes, as they are binding
      on you.</p>

    <h3>25. Notice of Changes and Use of Terms of Service.</h3>
    <p>We may provide notice to you relating to the Site and/or these Terms of Service by sending an e-mail to your last known
      e-mail address, and any such notice shall be deemed given and received on the day it is sent. A printed version of these
      Terms of Service and of any notices given to you in electronic form or otherwise shall be admissible in judicial or administrative
      proceedings based upon or relating to these Terms of Service to the same extent and subject to the same conditions as
      other business documents and records originally generated and maintained in printed form. You agree that any cause of
      action that you may desire to bring arising out of or related to these Terms of Service and/or the Site must commence
      within one (1) year after the cause of action arises. You may not use the Site or export the Content in violation of
      U.S. export laws and regulations. If you access the Site from a location outside the United States, you are responsible
      for compliance with all local laws.</p>

    <p>
      <strong>Last Updated: 11/13/17</strong>
    </p>
    </div>
)
}
}

export default Terms;
